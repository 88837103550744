import React from "react";
import {
  Box,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NorthIcon from "@mui/icons-material/North";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SouthIcon from "@mui/icons-material/South";
import { Column } from ".";
import { DataAction } from ".";
import { IconButtonWithTooltip } from "./IconButtonWithTooltip";
import { TranslationKey } from "../i18n";

export const DataTable: React.FC<{
  actions?: DataAction[];
  activeColumn?: boolean;
  busy?: boolean;
  columns: Column[];
  data: any;
  changeActiveHandler?: (item: any) => void;
  editHandler?: (item: any) => void;
  dataChangeHandler?: (value: any) => void;
  deleteHandler?: (index: number) => void;
  selectHandler?: (primaryKey: any) => void;
}> = ({
  actions,
  activeColumn,
  busy,
  columns,
  data,
  changeActiveHandler,
  editHandler,
  dataChangeHandler,
  deleteHandler,
}) => {
  const { t } = useTranslation();

  const getColumnCellValue = (column: Column, item: any) => {
    if (column.isBoolean) {
      return (
        <Switch
          id={`${column.key}-switch`}
          checked={item[column.key]}
          disabled={true}
        />
      );
    } else if (column.isDate) {
      return (
        item[column.key] && new Date(item[column.key]).toLocaleDateString()
      );
    } else if (column.mapping) {
      const mappingItem = column.mapping.find((m) => {
        return m.id === item[column.key];
      });

      if (mappingItem) {
        const text =
          mappingItem[
            column.mappingTextPropertyName
              ? column.mappingTextPropertyName
              : "name"
          ];

        return column.translateMappingTextProperty ? t(text) : text;
      } else {
        return "Not Found!";
      }
    } else {
      return item[column.key];
    }
  };

  const moveDown = (index: number) => {
    if (index === data.length - 1) return;

    moveItem(index, +1);
  };

  function moveItem(currentIndex: number, indexOffset: number) {
    const newIndex = currentIndex + indexOffset;
    const tempData = [...data];
    tempData.splice(newIndex, 0, tempData.splice(currentIndex, 1)[0]);

    if (dataChangeHandler) dataChangeHandler(tempData);
  }

  const moveUp = (index: number) => {
    if (index === 0) return;

    moveItem(index, -1);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        mb: "15px",
      }}
    >
      <Table className="dark-table">
        <TableHead sx={{ background: "#F7FAFF" }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.key}>{t(column.name)}</TableCell>
            ))}
            {activeColumn && (
              <TableCell key="active-column">
                {t(TranslationKey.Active)}
              </TableCell>
            )}
            {actions &&
              actions.filter((a) => a !== DataAction.Add).length > 0 && (
                <TableCell key="actions" style={{ width: "100px" }}>
                  {t(TranslationKey.Actions)}
                </TableCell>
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ? data.map((item: any, index: number) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCell key={column.key}>
                      {getColumnCellValue(column, item)}
                    </TableCell>
                  ))}
                  {activeColumn && (
                    <TableCell
                      key={`${item.id}-active-column`}
                      className="text-center"
                    >
                      <Switch
                        id={`${item.id}-active-switch`}
                        checked={item.active}
                        disabled={busy}
                        onClick={() =>
                          changeActiveHandler && changeActiveHandler(item)
                        }
                      />
                    </TableCell>
                  )}
                  {actions &&
                    actions.filter((a) => a !== DataAction.Add).length > 0 && (
                      <TableCell key="actions" align="center" width={"110px"}>
                        <Box
                          sx={{
                            display: "inline-block",
                          }}
                        >
                          {actions.includes(DataAction.Edit) && (
                            <IconButtonWithTooltip
                              title={t(TranslationKey.Edit)}
                              disabled={busy}
                              color="warning"
                              icon={<EditIcon fontSize="inherit" />}
                              onClick={() => editHandler && editHandler(item)}
                            />
                          )}
                          {actions.includes(DataAction.View) && (
                            <IconButtonWithTooltip
                              title={t(TranslationKey.View)}
                              disabled={busy}
                              color="info"
                              icon={<RemoveRedEyeIcon fontSize="inherit" />}
                              onClick={() => editHandler && editHandler(item)}
                            />
                          )}
                          {actions.includes(DataAction.Delete) && (
                            <IconButtonWithTooltip
                              title={t(TranslationKey.Delete)}
                              disabled={busy}
                              color="error"
                              icon={<DeleteIcon fontSize="inherit" />}
                              onClick={() =>
                                deleteHandler && deleteHandler(index)
                              }
                            />
                          )}
                          {actions.includes(DataAction.Sort) && (
                            <>
                              <IconButtonWithTooltip
                                title={t(TranslationKey.MoveUp)}
                                color="success"
                                disabled={busy}
                                icon={<NorthIcon fontSize="inherit" />}
                                onClick={() => moveUp(index)}
                              />
                              <IconButtonWithTooltip
                                title={t(TranslationKey.MoveDown)}
                                color="warning"
                                disabled={busy}
                                icon={<SouthIcon fontSize="inherit" />}
                                onClick={() => moveDown(index)}
                              />
                            </>
                          )}
                        </Box>
                      </TableCell>
                    )}
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
