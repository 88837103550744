import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Document from "../pages/Document";
import Home from "../pages/Home";
import Link from "../pages/Link";
import { Spinner } from "../components";

const DeleteMyAccount = React.lazy(() => import("../pages/DeleteMyAccount"));
const Login = React.lazy(() => import("../pages/Login"));

export const Anonymous: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/delete-my-account"
          element={
            <Suspense fallback={<Spinner />}>
              <DeleteMyAccount />
            </Suspense>
          }
        />
        <Route path="/document/:id" element={<Document />} />
        <Route
          path="/login"
          element={
            <Suspense fallback={<Spinner />}>
              <Login />
            </Suspense>
          }
        />
        <Route path="/user-profile/:username" element={<Link />} />
        <Route path="/recruitment/:id" element={<Link />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};
