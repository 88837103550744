import React from "react";
import { ArrayData, Input, Select } from ".";
import { CreateForm } from "../utils";
import { Languages, TranslationKey } from "../i18n";

export const GameHastags: React.FC<{
  parentFormProperty: any;
}> = ({ parentFormProperty }) => {
  const form = CreateForm({
    language: { validation: { required: true } },
    value: { validation: { required: true } },
  });

  return (
    <ArrayData
      columns={[
        {
          key: "language",
          name: TranslationKey.Language,
          mapping: Languages,
          translateMappingTextProperty: true,
        },
        {
          key: "value",
          name: TranslationKey.Value,
        },
      ]}
      form={form}
      label={TranslationKey.Hashtags}
      md={6}
      parentFormProperty={parentFormProperty}
    >
      <Select
        id="language"
        label={TranslationKey.Language}
        formProperty={form.language}
        options={Languages}
        translateTextProperty
      />
      <Input
        id="value"
        label={TranslationKey.Value}
        formProperty={form.value}
      />
    </ArrayData>
  );
};
