import React from "react";
import { ArrayData, Input, Select } from ".";
import { CreateForm } from "../utils";
import { Languages, TranslationKey } from "../i18n";

export const GameTranslatablePropBase: React.FC<{
  label: TranslationKey;
  parentFormProperty: any;
}> = ({ label, parentFormProperty }) => {
  const form = CreateForm({
    id: { validation: { numeric: true, required: true } },
    language: { validation: { required: true } },
    name: { validation: { required: true } },
  });

  return (
    <ArrayData
      columns={[
        {
          key: "id",
          name: TranslationKey.Id,
        },
        {
          key: "language",
          name: TranslationKey.Language,
          mapping: Languages,
          translateMappingTextProperty: true,
        },
        {
          key: "name",
          name: TranslationKey.Name,
        },
      ]}
      form={form}
      label={label}
      md={6}
      parentFormProperty={parentFormProperty}
      sortable
      duplicationCheckFunc={(item, data) =>
        !data.some((i) => i.id === item.id && i.language === item.language)
      }
    >
      <Input
        id="id"
        label={TranslationKey.Id}
        formProperty={form.id}
        type="number"
      />
      <Select
        id="language"
        label={TranslationKey.Language}
        formProperty={form.language}
        options={Languages}
        translateTextProperty
      />
      <Input id="name" label={TranslationKey.Name} formProperty={form.name} />
    </ArrayData>
  );
};
