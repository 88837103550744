import React from "react";
import { Box } from "@mui/material";
import { Logo } from "../components";

const Link: React.FC = () => {
  return (
    <Box
      component="main"
      sx={{
        maxWidth: "510px",
        ml: "auto",
        mr: "auto",
        mt: 10,
      }}
    >
      <Logo />
    </Box>
  );
};

export default Link;
