import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "../utils";
import { useTranslation } from "react-i18next";
import { TranslationKey } from "../i18n";

export const Modal: React.FC<{
  title: TranslationKey;
  children: React.ReactNode;
  form?: Form<any>;
  parentFormProperty: any;
  open: boolean;
  duplicationCheckFunc?: (item: any, data: any[]) => boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  title,
  children,
  form,
  parentFormProperty,
  open,
  duplicationCheckFunc,
  setOpen,
}) => {
  const { t } = useTranslation();

  const closeHandler = () => {
    form?.__clear();
    setOpen(false);
  };

  const saveHandler = () => {
    if (!form || !form.__validate()) return;

    if (Array.isArray(parentFormProperty.value)) {
      if (
        duplicationCheckFunc &&
        duplicationCheckFunc(form.__value, parentFormProperty.value)
      ) {
        const newValue = [...parentFormProperty.value, form.__value];
        parentFormProperty.changeHandler(newValue);
      } else if (
        !parentFormProperty.value.some(
          (i: any) => i[form.__primaryKey] === form.__value[form.__primaryKey]
        )
      ) {
        const newValue = [...parentFormProperty.value, form.__value];
        parentFormProperty.changeHandler(newValue);
      }
    } else {
      parentFormProperty.changeHandler([form.__value]);
    }

    form.__clear();
    parentFormProperty.blurHandler();
    setOpen(false);
  };

  return (
    <Dialog
      aria-labelledby="modal-title"
      fullWidth
      open={open}
      onClose={closeHandler}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="modal-title">
        {t(title)}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeHandler}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <form>
        <DialogContent>
          <Grid container spacing={2}>
            {children}
          </Grid>
        </DialogContent>
        {form && (
          <DialogActions>
            <Button autoFocus onClick={saveHandler}>
              {t(TranslationKey.Save)}
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};
