import { TranslationKey } from "./translation-key";

export const en = {
  translation: {
    [TranslationKey.Actions]: "Actions",
    [TranslationKey.Active]: "Active",
    [TranslationKey.AdminUsers]: "Admin users",
    [TranslationKey.AppName]: "ZAP",
    [TranslationKey.AtLeastOne]: "At least one",
    [TranslationKey.Block]: "Block",
    [TranslationKey.Blocked]: "Blocked",
    [TranslationKey.BlockedDevices]: "Blocked devices",
    [TranslationKey.Body]: "Body",
    [TranslationKey.Browse]: "Browse",
    [TranslationKey.ByAge]: "By age:",
    [TranslationKey.ByGame]: "By game:",
    [TranslationKey.ByGender]: "By gender:",
    [TranslationKey.ByJoin]: "By join:",
    [TranslationKey.Cancel]: "Cancel",
    [TranslationKey.ChangePassword]: "Change password",
    [TranslationKey.Confirm]: "Confirm",
    [TranslationKey.ConfirmNewPassword]: "Confirm new password",
    [TranslationKey.Created]: "Created.",
    [TranslationKey.CreatedAt]: "Created at",
    [TranslationKey.Day]: "Day",
    [TranslationKey.Delete]: "Delete",
    [TranslationKey.Deleted]: "Deleted.",
    [TranslationKey.DeleteMyAccount]: "Delete my account",
    [TranslationKey.Details]: "Details",
    [TranslationKey.DisplayName]: "Display name",
    [TranslationKey.Documents]: "Documents",
    [TranslationKey.DropFilesOrClick]: "Drop files here or click to upload.",
    [TranslationKey.DropFilesOrClickTo]: "Drop files here or click to",
    [TranslationKey.DuplicateUsername]: "Duplicate username.",
    [TranslationKey.Edit]: "Edit",
    [TranslationKey.Email]: "Email",
    [TranslationKey.Empty]: "Empty",
    [TranslationKey.English]: "English",
    [TranslationKey.Female]: "Female",
    [TranslationKey.Full]: "Full",
    [TranslationKey.Games]: "Games",
    [TranslationKey.Hashtags]: "Hashtags",
    [TranslationKey.Home]: "Home",
    [TranslationKey.Hour]: "Hour",
    [TranslationKey.Id]: "Id",
    [TranslationKey.InvalidEmail]: "Invalid email address.",
    [TranslationKey.InvalidNumber]: "Invalid number.",
    [TranslationKey.Items]: "item(s)",
    [TranslationKey.Japanese]: "日本語",
    [TranslationKey.Language]: "Language",
    [TranslationKey.LocalNotifications]: "Local notifications",
    [TranslationKey.Login]: "Login",
    [TranslationKey.LoginInformationError]: "Login information error.",
    [TranslationKey.Logout]: "Logout",
    [TranslationKey.Male]: "Male",
    [TranslationKey.MaxNumberOfPlayersToRecruit]:
      "Max number of players to recruit",
    [TranslationKey.Minute]: "Minute",
    [TranslationKey.Modes]: "Modes",
    [TranslationKey.MoveDown]: "Move down",
    [TranslationKey.MoveUp]: "Move up",
    [TranslationKey.Name]: "Name",
    [TranslationKey.NameTranslations]: "Name translations",
    [TranslationKey.New]: "New",
    [TranslationKey.NewPassword]: "New password",
    [TranslationKey.NoDataAvailable]: "No data available.",
    [TranslationKey.None]: "None",
    [TranslationKey.OfficialMessages]: "Official messages",
    [TranslationKey.OldPassword]: "Old password",
    [TranslationKey.Order]: "Order",
    [TranslationKey.OtherGender]: "Other",
    [TranslationKey.OTP]: "OTP",
    [TranslationKey.Password]: "Password",
    [TranslationKey.PasswordEasy]: "The new password is too easy to guess.",
    [TranslationKey.PasswordNotIdentical]:
      "Confirmation password is not identical.",
    [TranslationKey.PasswordRequirement_1]: "Minimum 8, maximum 26 characters.",
    [TranslationKey.PasswordRequirement_2]:
      "Must include at least 1 uppercase letter, 1 lowercase letter and 1 number.",
    [TranslationKey.Platforms]: "Platforms",
    [TranslationKey.PleaseWait]: "Please wait...",
    [TranslationKey.PrivacyPolicy]: "Privacy Policy",
    [TranslationKey.Ranks]: "Ranks",
    [TranslationKey.Reason]: "Reason",
    [TranslationKey.Recruitments]: "Recruitments",
    [TranslationKey.RemoteNotifications]: "Remote notifications",
    [TranslationKey.RemoveAll]: "Remove all",
    [TranslationKey.Repeat]: "Repeat",
    [TranslationKey.ReportedAt]: "Reported at",
    [TranslationKey.ReportedByUserId]: "Reported by user id",
    [TranslationKey.ReportedUsers]: "Reported users",
    [TranslationKey.Required]: "Required",
    [TranslationKey.Role]: "Role",
    [TranslationKey.SalmonRunRanks]: "Salmon run ranks",
    [TranslationKey.Save]: "Save",
    [TranslationKey.Saving]: "Saving...",
    [TranslationKey.Search]: "Search",
    [TranslationKey.Tags]: "Tags",
    [TranslationKey.TermsOfUse]: "Terms of Use",
    [TranslationKey.Text]: "Text",
    [TranslationKey.ThroughYourMachine]: "through your machine",
    [TranslationKey.Title]: "Title",
    [TranslationKey.Type]: "Type",
    [TranslationKey.Updated]: "Updated.",
    [TranslationKey.UserDisplayName]: "User display name",
    [TranslationKey.Username]: "Username",
    [TranslationKey.Users]: "Users",
    [TranslationKey.YourPassword]: "Your password,",
    [TranslationKey.Value]: "Value",
    [TranslationKey.View]: "View",
    [TranslationKey.Viewing]: "Viewing",
    [TranslationKey.WeakPassword]: "Weak password.",
    [TranslationKey.XpFrom]: "XP from",
    [TranslationKey.XpTo]: "XP to",

    // Report User
    [TranslationKey.SeekingRomanticEncounters]: "Seeking romantic encounters",
    [TranslationKey.HateSpeech]: "Hate speech",
    [TranslationKey.NudeSexual]: "Nude or sexual content",
    [TranslationKey.Violent]: "Violent content",
    [TranslationKey.Harassment]: "Harassment",
    [TranslationKey.Impersonation]: "Impersonation",
    [TranslationKey.FraudulentActivity]: "Fraudulent activity",
    [TranslationKey.Other]: "Other",
  },
};
