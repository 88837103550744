import * as React from "react";
import { AppBar, Toolbar, IconButton, Stack, Typography } from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import Tooltip from "@mui/material/Tooltip";
import CurrentDate from "./CurrentDate";
import Language from "./Language";
import Profile from "./Profile";

export const TopNavbar: React.FC<{
  toogleActive: () => void;
}> = ({ toogleActive }) => {
  return (
    <>
      <div className="topNavbarDark">
        <AppBar
          color="inherit"
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 4px 20px rgba(47, 143, 232, 0.07)",
            py: "6px",
            mb: "30px",
            position: "sticky",
          }}
          className="top-navbar-for-dark"
        >
          <Toolbar>
            <Tooltip title="Hide/Show" arrow>
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                onClick={toogleActive}
              >
                <FormatAlignLeftIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Typography component="div" sx={{ flexGrow: 1 }}></Typography>

            <Stack direction="row" spacing={2}>
              <CurrentDate />
              <Language />
              <Profile />
            </Stack>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};
