import React, { useState, useEffect } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./CurrentDate.css";

const CurrentDate: React.FC = () => {
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    /* @ts-ignore */
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const date = new Date();
    setCurrentDate(formatter.format(date));
  }, []);

  return (
    <div className="currentDate">
      <CalendarMonthIcon className="currentDateIcon" fontSize="small" />
      {currentDate}
    </div>
  );
};

export default CurrentDate;
