import React from "react";
import { Box, Card, Typography } from "@mui/material";

export const StatsView: React.FC<{
  children: React.ReactNode;
  icon: React.ReactNode;
  title: string;
  total?: number;
}> = ({ children, icon, title, total }) => {
  return (
    <Card
      sx={{
        borderRadius: "10px",
        boxShadow: "none",
        mb: "15px",
        p: "30px 20px 20px",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box
          sx={{
            alignContent: "center",
            background: "rgba(85, 112, 241, 0.12)",
            borderRadius: "5px",
            height: "50px",
            textAlign: "center",
            width: "50px",
          }}
          className="mr-15px"
        >
          {icon}
        </Box>
        <Box>
          <Typography component="p" sx={{ fontSize: "13px", mb: "5px" }}>
            {title}
          </Typography>
          <Typography variant="h1" sx={{ fontSize: 28, fontWeight: 700 }}>
            {total?.toLocaleString()}
          </Typography>
        </Box>
      </Box>
      {children}
    </Card>
  );
};
