import React, { Suspense, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Sidebar, Spinner, TopNavbar } from "../components";
import { Authentication } from "../services";
import { Role } from "../constants";

const AdminHome = React.lazy(() => import("../pages/AdminHome"));
const AdminUsers = React.lazy(() => import("../pages/AdminUsers"));
const ChangePassword = React.lazy(() => import("../pages/ChangePassword"));
const Documents = React.lazy(() => import("../pages/Documents"));
const Games = React.lazy(() => import("../pages/Games"));
const LocalNotifications = React.lazy(() => import("../pages/LocalNotifications"));
const OfficialMessages = React.lazy(() => import("../pages/OfficialMessages"));
const Recruitments = React.lazy(() => import("../pages/Recruitments"));
const RemoteNotifications = React.lazy(() => import("../pages/RemoteNotifications"));
const ReportedUsers = React.lazy(() => import("../pages/ReportedUsers"));
const Users = React.lazy(() => import("../pages/Users"));

export const Authorized: React.FC = () => {
  const [active, setActive] = useState(false);

  const toogleActive = () => {
    setActive(!active);
  };

  return (
    <BrowserRouter>
      <div className={`main-wrapper-content ${active && "active"}`}>
        <TopNavbar toogleActive={toogleActive} />
        <Sidebar toogleActive={toogleActive} />
        <div className="main-content">
          <Routes>
            <Route
              path="/admin-home"
              element={
                <Suspense fallback={<Spinner />}>
                  <div className="main-content">
                    <AdminHome />
                  </div>
                </Suspense>
              }
            />
            <Route
              path="/change-password"
              element={
                <Suspense fallback={<Spinner />}>
                  <ChangePassword />
                </Suspense>
              }
            />
            {Authentication.allowedRoles([Role.Admin]) && (
              <>
                <Route
                  path="/admin-users"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <AdminUsers />
                    </Suspense>
                  }
                />
                <Route
                  path="/documents"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <Documents />
                    </Suspense>
                  }
                />
                <Route
                  path="/games"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <Games />
                    </Suspense>
                  }
                />
                <Route
                  path="/local-notifications"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <LocalNotifications />
                    </Suspense>
                  }
                />
                <Route
                  path="/official-messages"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <OfficialMessages />
                    </Suspense>
                  }
                />
                <Route
                  path="/recruitments"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <Recruitments />
                    </Suspense>
                  }
                />
                <Route
                  path="/remote-notifications"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <RemoteNotifications />
                    </Suspense>
                  }
                />
                <Route
                  path="/reported-users"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <ReportedUsers />
                    </Suspense>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <Users />
                    </Suspense>
                  }
                />
              </>
            )}
            <Route
              path="*"
              element={
                <Suspense fallback={<Spinner />}>
                  <AdminHome />
                </Suspense>
              }
            />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
};
