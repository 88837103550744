import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Stats, UsersStats } from "../models";
import { StatsKeys } from "../constants";
import { StatsView } from "./StatsView";
import { TranslationKey } from "../i18n";

export const UsersStatsView: React.FC<{
  stats?: Stats[];
}> = ({ stats }) => {
  const { t } = useTranslation();
  const [userStats, setUserStats] = useState<UsersStats>();

  useEffect(() => {
    setUserStats(
      stats?.find((s) => s.id === StatsKeys.Users) as UsersStats | undefined
    );
  }, [stats]);

  return (
    <>
      {userStats && (
        <StatsView
          icon={<PeopleAltIcon fontSize="large" />}
          title={t(TranslationKey.Users)}
          total={userStats.total}
        >
          <Box
            sx={{
              mt: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 500,
                mb: "10px",
              }}
            >
              {t(TranslationKey.ByAge)}
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                mb: "15px",
              }}
            >
              <Table className="dark-table">
                <TableHead sx={{ background: "#F7FAFF" }}>
                  <TableRow>
                    <TableCell>{`<12`}</TableCell>
                    <TableCell>12 ~ 15</TableCell>
                    <TableCell>15 ~ 20</TableCell>
                    <TableCell>20 ~ 25</TableCell>
                    <TableCell>25 ~ 30</TableCell>
                    <TableCell>30 ~ 35</TableCell>
                    <TableCell>35 ~ 40</TableCell>
                    <TableCell>{`>40`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{userStats.lessThan12.toLocaleString()}</TableCell>
                    <TableCell>{userStats.from12To15.toLocaleString()}</TableCell>
                    <TableCell>{userStats.from15To20.toLocaleString()}</TableCell>
                    <TableCell>{userStats.from20To25.toLocaleString()}</TableCell>
                    <TableCell>{userStats.from25To30.toLocaleString()}</TableCell>
                    <TableCell>{userStats.from30To35.toLocaleString()}</TableCell>
                    <TableCell>{userStats.from35To40.toLocaleString()}</TableCell>
                    <TableCell>{userStats.moreThan40.toLocaleString()}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              mt: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 500,
                mb: "10px",
              }}
            >
              {t(TranslationKey.ByGender)}
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                mb: "15px",
              }}
            >
              <Table className="dark-table">
                <TableHead sx={{ background: "#F7FAFF" }}>
                  <TableRow>
                    <TableCell>{t(TranslationKey.Female)}</TableCell>
                    <TableCell>{t(TranslationKey.Male)}</TableCell>
                    <TableCell>{t(TranslationKey.OtherGender)}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{userStats.female.toLocaleString()}</TableCell>
                    <TableCell>{userStats.male.toLocaleString()}</TableCell>
                    <TableCell>{userStats.other.toLocaleString()}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </StatsView>
      )}
    </>
  );
};
