import React from "react";
import { ArrayData, Input, Select } from ".";
import { CreateForm } from "../utils";
import { Languages, TranslationKey } from "../i18n";

export const GameNameTranslations: React.FC<{
  parentFormProperty: any;
}> = ({ parentFormProperty }) => {
  const form = CreateForm({
    language: { validation: { required: true } },
    name: { validation: { required: true } },
  });

  return (
    <ArrayData
      columns={[
        {
          key: "language",
          name: TranslationKey.Language,
          mapping: Languages,
          translateMappingTextProperty: true,
        },
        {
          key: "name",
          name: TranslationKey.Name,
        },
      ]}
      form={form}
      label={TranslationKey.NameTranslations}
      md={6}
      parentFormProperty={parentFormProperty}
    >
      <Select
        id="language"
        label={TranslationKey.Language}
        formProperty={form.language}
        options={Languages}
        translateTextProperty
      />
      <Input id="name" label={TranslationKey.Name} formProperty={form.name} />
    </ArrayData>
  );
};
