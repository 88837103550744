import { TranslationKey } from "./translation-key";

export const ja = {
  translation: {
    [TranslationKey.Actions]: "アクション",
    [TranslationKey.Active]: "アクティブ",
    [TranslationKey.AdminUsers]: "管理者ユーザー",
    [TranslationKey.AppName]: "ZAP",
    [TranslationKey.AtLeastOne]: "最低 1 人",
    [TranslationKey.Block]: "ブロックする",
    [TranslationKey.Blocked]: "ブロック済み",
    [TranslationKey.BlockedDevices]: "ブロックされたデバイス",
    [TranslationKey.Body]: "本文",
    [TranslationKey.Browse]: "参照",
    [TranslationKey.ByAge]: "年齢別:",
    [TranslationKey.ByGame]: "ゲーム別:",
    [TranslationKey.ByGender]: "性別別:",
    [TranslationKey.ByJoin]: "参加することで",
    [TranslationKey.Cancel]: "キャンセル",
    [TranslationKey.ChangePassword]: "パスワードの変更",
    [TranslationKey.Confirm]: "確認する",
    [TranslationKey.ConfirmNewPassword]: "新しいパスワードの確認",
    [TranslationKey.Created]: "作成日",
    [TranslationKey.CreatedAt]: "作成日時",
    [TranslationKey.Day]: "日",
    [TranslationKey.Delete]: "削除する",
    [TranslationKey.Deleted]: "削除済み",
    [TranslationKey.DeleteMyAccount]: "アカウントの削除",
    [TranslationKey.Details]: "詳細",
    [TranslationKey.DisplayName]: "表示名",
    [TranslationKey.Documents]: "ドキュメント",
    [TranslationKey.DropFilesOrClick]:
      "ここにファイルをドロップするか、クリックしてアップロードします。",
    [TranslationKey.DropFilesOrClickTo]:
      "ここにファイルをドロップするか、クリックして",
    [TranslationKey.DuplicateUsername]: "ユーザー名が重複しています。",
    [TranslationKey.Edit]: "編集",
    [TranslationKey.Email]: "メール",
    [TranslationKey.Empty]: "空室",
    [TranslationKey.English]: "English",
    [TranslationKey.Female]: "女性",
    [TranslationKey.Full]: "満室",
    [TranslationKey.Games]: "ゲーム",
    [TranslationKey.Hashtags]: "ハッシュタグ",
    [TranslationKey.Home]: "ホーム",
    [TranslationKey.Hour]: "時間",
    [TranslationKey.Id]: "ID",
    [TranslationKey.InvalidEmail]: "メールアドレスが無効です。",
    [TranslationKey.InvalidNumber]: "番号が無効です。",
    [TranslationKey.Items]: "アイテム",
    [TranslationKey.Japanese]: "日本語",
    [TranslationKey.Language]: "言語",
    [TranslationKey.LocalNotifications]: "ローカル通知",
    [TranslationKey.Login]: "ログイン",
    [TranslationKey.LoginInformationError]: "ログイン情報エラーです。",
    [TranslationKey.Logout]: "ログアウト",
    [TranslationKey.Male]: "男性",
    [TranslationKey.MaxNumberOfPlayersToRecruit]:
      "募集できるプレイヤーの最大数",
    [TranslationKey.Minute]: "分",
    [TranslationKey.Modes]: "モード",
    [TranslationKey.MoveDown]: "下に移動",
    [TranslationKey.MoveUp]: "上に移動",
    [TranslationKey.Name]: "名前",
    [TranslationKey.NameTranslations]: "名前の翻訳",
    [TranslationKey.New]: "新規",
    [TranslationKey.NewPassword]: "新しいパスワード",
    [TranslationKey.NoDataAvailable]: "データがありません。",
    [TranslationKey.None]: "なし",
    [TranslationKey.OfficialMessages]: "公式メッセージ",
    [TranslationKey.OldPassword]: "古いパスワード",
    [TranslationKey.Order]: "注文",
    [TranslationKey.OtherGender]: "その他",
    [TranslationKey.OTP]: "OTP",
    [TranslationKey.Password]: "パスワード",
    [TranslationKey.PasswordEasy]: "新しいパスワードは推測されやすいです。",
    [TranslationKey.PasswordNotIdentical]:
      "確認用のパスワードが間違っています。",
    [TranslationKey.PasswordRequirement_1]: "最小 8 文字、最大 26 文字。",
    [TranslationKey.PasswordRequirement_2]:
      "少なくとも 1 つの大文字、1 つの小文字、1 つの数字を含める必要があります。",
    [TranslationKey.Platforms]: "プラットフォーム",
    [TranslationKey.PleaseWait]: "お待ちください...",
    [TranslationKey.PrivacyPolicy]: "プライバシーポリシー",
    [TranslationKey.Ranks]: "ランク",
    [TranslationKey.Reason]: "理由",
    [TranslationKey.Recruitments]: "募集",
    [TranslationKey.RemoteNotifications]: "リモート通知",
    [TranslationKey.RemoveAll]: "すべて削除",
    [TranslationKey.Repeat]: "繰り返し",
    [TranslationKey.ReportedAt]: "報告日時",
    [TranslationKey.ReportedByUserId]: "報告者ユーザーID",
    [TranslationKey.ReportedUsers]: "報告されたユーザー",
    [TranslationKey.Required]: "必須",
    [TranslationKey.Role]: "役割",
    [TranslationKey.SalmonRunRanks]: "サーモンランランク",
    [TranslationKey.Save]: "保存",
    [TranslationKey.Saving]: "保存中...",
    [TranslationKey.Search]: "検索",
    [TranslationKey.Tags]: "タグ",
    [TranslationKey.TermsOfUse]: "利用規約",
    [TranslationKey.Text]: "テキスト",
    [TranslationKey.ThroughYourMachine]: "マシン経由",
    [TranslationKey.Title]: "タイトル",
    [TranslationKey.Type]: "タイプ",
    [TranslationKey.Updated]: "更新されました。",
    [TranslationKey.UserDisplayName]: "ユーザー表示名",
    [TranslationKey.Username]: "ユーザーID",
    [TranslationKey.Users]: "ユーザー",
    [TranslationKey.YourPassword]: " パスワード、",
    [TranslationKey.Value]: "キーワード",
    [TranslationKey.View]: "表示",
    [TranslationKey.Viewing]: "表示",
    [TranslationKey.WeakPassword]: "弱いパスワードです。",
    [TranslationKey.XpFrom]: "XP から",
    [TranslationKey.XpTo]: "XP まで",

    // Report User
    [TranslationKey.SeekingRomanticEncounters]: "出会い目的",
    [TranslationKey.HateSpeech]: "ヘイトスピーチ",
    [TranslationKey.NudeSexual]: "ヌードや性的なコンテンツ",
    [TranslationKey.Violent]: "暴力的なコンテンツ",
    [TranslationKey.Harassment]: "嫌がらせ",
    [TranslationKey.Impersonation]: "なりすまし",
    [TranslationKey.FraudulentActivity]: "詐欺行為",
    [TranslationKey.Other]: "その他",
  },
};
