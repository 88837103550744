export enum Routes {
  AdminUsers = "admin-users",
  AdminUsersAuthentication = "admin-users/authentication",
  AdminUsersChangePassword = "admin-users/change-password",
  Document = "document",
  Documents = "documents",
  Games = "games",
  LocalNotifications = "local-notifications",
  OfficialMessages = "official-messages",
  Recruitments = "recruitments/admin",
  RemoteNotifications = "remote-notifications",
  ReportedUsers = "reported-users",
  ReportedUsersBlock = "reported-users/block",
  Stats = "stats",
  Users = "users",
  UsersDeleteByEmail = "users/delete-by-email",
  UsersGetDeleteOtpByEmail = "users/get-delete-otp-by-email",
}
