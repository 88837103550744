import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "./en";
import { ja } from "./ja";
import { AdminLanguageKey, Language } from "./language";

i18n.use(initReactI18next).init({
  fallbackLng: localStorage.getItem(AdminLanguageKey.name) || Language.en,
  interpolation: {
    escapeValue: false,
  },
  lng: localStorage.getItem(AdminLanguageKey.name) || Language.en,
  resources: {
    en,
    ja,
  },
});

export default i18n;
export * from "./language";
export * from "./translation-key";
