import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { TranslationKey } from "../i18n";

export const ArrayDataHeader: React.FC<{
  label: TranslationKey;
  busy?: boolean;
  addHandler: () => void;
}> = ({ label, busy, addHandler }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #EEF0F7",
        paddingBottom: "10px",
        mb: "20px",
      }}
      className="for-dark-bottom-border"
    >
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 500,
          mt: "10px",
        }}
      >
        {t(label)}
      </Typography>
      <Button
        variant="contained"
        sx={{
          textTransform: "capitalize",
          borderRadius: "8px",
          fontWeight: "500",
          fontSize: "13px",
          color: "#fff !important",
        }}
        disabled={busy}
        onClick={addHandler}
      >
        <AddIcon
          sx={{ position: "relative", top: "-1px" }}
          className="mr-5px"
        />{" "}
        {t(TranslationKey.New)}
      </Button>
    </Box>
  );
};
