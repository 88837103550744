export const LocalNotificationsTypes = [
  { id: "d-1-en", name: "D1 EN" },
  { id: "d-2-en", name: "D2 EN" },
  { id: "d-3-en", name: "D3 EN" },
  { id: "d-4-en", name: "D4 EN" },
  { id: "d-5-en", name: "D5 EN" },
  { id: "d-6-en", name: "D6 EN" },
  { id: "d-7-en", name: "D7 EN" },
  { id: "d-1-jp", name: "D1 JP" },
  { id: "d-2-jp", name: "D2 JP" },
  { id: "d-3-jp", name: "D3 JP" },
  { id: "d-4-jp", name: "D4 JP" },
  { id: "d-5-jp", name: "D5 JP" },
  { id: "d-6-jp", name: "D6 JP" },
  { id: "d-7-jp", name: "D7 JP" },
  { id: "other", name: "Other" },
];
