export const GetGooglePlay = () => {
  return (
    <img
      alt="Google Play"
      src="/images/get-it-on-google-play.png"
      style={{ cursor: "pointer" }}
      width={150}
      onClick={() =>
        window.open(
          "https://play.google.com/store/apps/details?id=jp.zapinc.zap"
        )
      }
    />
  );
};
