import { initStore } from ".";
import { AdminLanguageKey, Language } from "../i18n";

const configureStore = () => {
  const actions = {
    LANGUAGE: (state: { language: Language }, value: Language) => ({
      language: (state.language = value),
    }),
  };

  const language = localStorage.getItem(AdminLanguageKey.name) || Language.en;
  initStore(actions, { language });
};

export default configureStore;
