export const DownloadAppStore = () => {
  return (
    <img
      alt="App Store"
      src="/images/download-on-the-app-store.svg"
      style={{ cursor: "pointer" }}
      width={135}
      onClick={() =>
        window.open(
          "https://apps.apple.com/us/app/zap-make-gaming-friends/id6502455108"
        )
      }
    />
  );
};
