import React, { useState } from "react";
import { FormHelperText, Grid } from "@mui/material";
import { ArrayDataHeader } from "./ArrayDataHeader";
import { Column, DataAction } from ".";
import { DataTable } from "./DataTable";
import { Form } from "../utils";
import { Modal } from "./Modal";
import { swalDelete } from "../constants";
import { TranslationKey } from "../i18n";

export const ArrayData: React.FC<{
  busy?: boolean;
  children: React.ReactNode;
  columns: Column[];
  form: Form<any>;
  label: TranslationKey;
  md?: number;
  parentFormProperty: any;
  sortable?: boolean;
  duplicationCheckFunc?: (item: any, data: any[]) => boolean;
}> = ({
  label,
  busy,
  children,
  columns,
  form,
  parentFormProperty,
  md,
  sortable,
  duplicationCheckFunc,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const actions = () => {
    return sortable
      ? [DataAction.Delete, DataAction.Sort]
      : [DataAction.Delete];
  };

  const addHandler = () => {
    setModalOpen(true);
  };

  const deleteHandler = async (index: number) => {
    const result = await swalDelete();

    if (result) {
      const items = [...parentFormProperty.value];
      items.splice(index, 1);
      parentFormProperty.changeHandler(items);
    }
  };

  return (
    <>
      <Grid item md={md} sm={12} xs={12}>
        <ArrayDataHeader label={label} busy={busy} addHandler={addHandler} />
        <DataTable
          actions={actions()}
          columns={columns}
          data={parentFormProperty.value}
          dataChangeHandler={parentFormProperty.changeHandler}
          deleteHandler={deleteHandler}
        />
        {parentFormProperty.error && (
          <FormHelperText error={!parentFormProperty.valid}>
            {parentFormProperty.error}
          </FormHelperText>
        )}
      </Grid>
      <Modal
        title={label}
        form={form}
        parentFormProperty={parentFormProperty}
        open={modalOpen}
        duplicationCheckFunc={duplicationCheckFunc}
        setOpen={setModalOpen}
      >
        {children}
      </Modal>
    </>
  );
};
