import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import GamepadIcon from "@mui/icons-material/Gamepad";
import { RecruitmentsStats, Stats } from "../models";
import { StatsKeys } from "../constants";
import { StatsView } from "./StatsView";
import { TranslationKey } from "../i18n";

const excludedProps = ["atLeastOne", "empty", "full", "id", "total"];

export const RecruitmentsStatsView: React.FC<{
  stats?: Stats[];
}> = ({ stats }) => {
  const [recruitmentsStats, setRecruitmentsStats] =
    useState<RecruitmentsStats>();
  const [recruitmentsStatsKeys, setRecruitmentsStatsKeys] =
    useState<string[]>();
  const { t } = useTranslation();

  useEffect(() => {
    const rs = stats?.find((s) => s.id === StatsKeys.Recruitments);

    if (rs) {
      setRecruitmentsStats(rs as RecruitmentsStats);

      const keys: string[] = [];

      for (let key in rs) {
        if (!excludedProps.includes(key)) keys.push(key);
      }

      setRecruitmentsStatsKeys(keys);
    }
  }, [stats]);

  return (
    <>
      {recruitmentsStats && recruitmentsStatsKeys && (
        <StatsView
          icon={<GamepadIcon fontSize="large" />}
          title={t(TranslationKey.Recruitments)}
          total={recruitmentsStats.total}
        >
          <Box
            sx={{
              mt: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 500,
                mb: "10px",
              }}
            >
              {t(TranslationKey.ByGame)}
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                mb: "15px",
              }}
            >
              <Table className="dark-table">
                <TableHead sx={{ background: "#F7FAFF" }}>
                  <TableRow>
                    {recruitmentsStatsKeys.map((k) => (
                      <TableCell key={k}>{k}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {recruitmentsStatsKeys.map((k) => (
                      <TableCell key={k}>
                        {recruitmentsStats[k].toLocaleString()}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              mt: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 500,
                mb: "10px",
              }}
            >
              {t(TranslationKey.ByJoin)}
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                mb: "15px",
              }}
            >
              <Table className="dark-table">
                <TableHead sx={{ background: "#F7FAFF" }}>
                  <TableRow>
                    <TableCell>{t(TranslationKey.Full)}</TableCell>
                    <TableCell>{t(TranslationKey.AtLeastOne)}</TableCell>
                    <TableCell>{t(TranslationKey.Empty)}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {recruitmentsStats.full?.toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {recruitmentsStats.atLeastOne?.toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {recruitmentsStats.empty?.toLocaleString()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </StatsView>
      )}
    </>
  );
};
