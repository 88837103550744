import React from "react";
import { Box } from "@mui/system";
import { Grid, Link, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageSwitch, Logo, StoreLinks } from "../components";
import { TranslationKey } from "../i18n";
import { useStore } from "../store";

const Home: React.FC = () => {
  const { language } = useStore()[0];
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      component="main"
      sx={{
        maxWidth: "510px",
        ml: "auto",
        mr: "auto",
        padding: "100px 0 100px",
      }}
    >
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Logo />
        <Stack
          sx={{
            alignItems: "center",
            display: "flex",
            mt: "10px",
          }}
        >
          <h1>{t(TranslationKey.AppName)}</h1>
          <div dir="ltr" style={{ display: "inline-block" }}>
            <Link
              component="button"
              onClick={() => navigate("/delete-my-account")}
            >
              {t(TranslationKey.DeleteMyAccount)}
            </Link>
            <span className="ml-10px mr-10px"></span>
            <Link
              component="button"
              onClick={() => navigate(`/document/privacy-policy-${language}`)}
            >
              {t(TranslationKey.PrivacyPolicy)}
            </Link>
            <span className="ml-10px mr-10px"></span>
            <Link
              component="button"
              onClick={() => navigate(`/document/terms-of-use-${language}`)}
            >
              {t(TranslationKey.TermsOfUse)}
            </Link>
          </div>
        </Stack>
        <LanguageSwitch />
        <StoreLinks />
      </Grid>
    </Box>
  );
};

export default Home;
