import React from "react";
import { Stack } from "@mui/material";
import { DownloadAppStore } from "./DownloadAppStore";
import { GetGooglePlay } from "./GetGooglePlay";

export const StoreLinks: React.FC = () => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        display: "flex",
        mt: "10px",
      }}
    >
      <div dir="ltr" style={{ display: "inline-block" }}>
        <GetGooglePlay />
        <span className="ml-15px mr-15px"></span>
        <DownloadAppStore />
      </div>
    </Stack>
  );
};
